import { fullName, resourceUrl } from '@/utils/dataExtractors'
export const segmentColumns = (actions: {[key: string]: Function}) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Start",
      title: "Start",
      key: 'attributes.start',
      sortable: true,
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Ende",
      key: 'attributes.end',
      options: {type: 'date'},
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "BK",
      key: 'meta.cGName',
      // options: {
      //   hrefFunction: (data: any) => {
      //     const cgi = data.item.attributes?.careGiverInstance
      //     return cgi ? resourceUrl(cgi) : '#'
      //   }
      // }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Anreise",
      title: "Anreise",
      key: 'attributes.cgiArrival',
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Abreise",
      key: 'attributes.cgiDeparture',
      options: {type: 'date'},
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Preis",
      key: 'attributes.price',
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "provPfS",
      key: 'attributes.provPfs',
      sortable: true,
      seller: false
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "provSeller",
      title: "provSeller",
      key: 'attributes.provSeller',
      sortable: true,
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Agenturabrechnunge",
      key: 'meta.agencyBillNr',
      // options: {
      //   hrefFunction: (data: any) => {
      //     const cgi = data.item.attributes?.careGiverInstance
      //     return cgi ? resourceUrl(cgi) : '#'
      //   }
      // }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Vertriebsrechnunge",
      key: 'meta.sellerBillNr',
      // options: {
      //   hrefFunction: (data: any) => {
      //     const cgi = data.item.attributes?.careGiverInstance
      //     return cgi ? resourceUrl(cgi) : '#'
      //   }
      // }
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bearbeiten',
            icon: ['fas', 'pencil-alt'],
            action: actions.editSegment,
          },
          // {
          //   title: 'Löschen',
          //   icon: ['fad', 'trash'],
          //   action: actions.deleteSegment,
          // }
        ]
      }
    },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "segDiscPfS",
  //   key: 'attributes.segmentDiscountPfs',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "segDiscVP",
  //   key: 'attributes.segmentDiscountSeller',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "segDiscAg",
  //   key: 'attributes.segmentDiscountAgency',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "conDiscPfS",
  //   key: 'attributes.contractDiscountPfs',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "conDiscVP",
  //   key: 'attributes.contractDiscountSeller',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "conDiscAg",
  //   key: 'attributes.contractDiscountAgency',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "Reisetyp",
  //   key: 'attributes.travelType',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "Reisekosten",
  //   key: 'attributes.travelCost',
  // }
  ]
}

export const segmentColumnsSeller = (actions: {[key: string]: Function}) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Start",
      title: "Start",
      key: 'attributes.start',
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Ende",
      key: 'attributes.end',
      options: {type: 'date'},
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Anreise",
      title: "Anreise",
      key: 'attributes.cgiArrival',
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Abreise",
      key: 'attributes.cgiDeparture',
      options: {type: 'date'},
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "BK",
      key: 'attributes',
      options: {
        contentFunction: (data: any) => {
          const cg = data.item.attributes.careGiverInstance?.attributes?.care_giver
          return cg ? fullName(cg) : 'Kein Name'
        },
        // hrefFunction: (data: any) => resourceUrl(data.item.attributes.careGiverInstance)
        hrefFunction: (data: any) => {
          const cgi = data.item.attributes?.careGiverInstance
          return cgi ? resourceUrl(cgi) : '#'
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Preis",
      key: 'attributes.price',
      seller: true,
      options: {
        contentFunction: (data: any) => {
          const seg = data.item.attributes
          return seg.price + seg.provPfs + seg.provSeller
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Abrechnung",
      label: "Abrechnung",
      key: 'attributes.seller_bill.attributes.billNr'
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Führerschein",
      label: "Führerschein",
      key: 'attributes.driversLicense',
      sortable: true,
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Nachtpflege",
      label: "Nachtpflege",
      key: 'attributes.nightCare',
      sortable: true,
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "2 PB HH",
      label: "2 PB HH",
      key: 'attributes.dubCr',
      sortable: true,
      seller: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "2 Pers. HH",
      label: "2 Pers. HH",
      key: 'attributes.dubHh',
      sortable: true,
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bearbeiten',
            icon: ['fas', 'pencil-alt'],
            action: actions.editSegment,
          },
          // {
          //   title: 'Löschen',
          //   icon: ['fad', 'trash'],
          //   action: actions.deleteSegment,
          // }
        ]
      }
    }
  ]
}

export const providerSegmentColumns = (editSegment: Function) => {
  return [
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "id",
    //   key: 'id',
    //   // sortable: true,
    // },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Start",
      key: 'attributes.start',
      // sortable: true,
      options: {
        // contentFunction: (data) => {console.log({data})}
        type: 'date'
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Ende",
      key: 'attributes.end',
      options: {type: 'date'},
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Anreise",
    //   key: 'attributes.cgiArrival',
    //   // sortable: true,
    //   options: {
    //     // contentFunction: (data) => {console.log({data})}
    //     type: 'date'
    //   }
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Abreise",
    //   key: 'attributes.cgiDeparture',
    //   options: {type: 'date'},
    // },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "BK",
      key: 'attributes',
      options: {
        contentFunction: (data: any) => {
          const cg = data.item.meta.cG
          return cg ? fullName(cg) : 'Kein Name'
        },
        // hrefFunction: (data: any) => resourceUrl(data.item.attributes.careGiverInstance)
        hrefFunction: (data: any) => {
          const cgi = data.item.meta.cGI
          return cgi ? resourceUrl(cgi) : '#'
        }
      }
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Preis",
    //   key: 'attributes.price',
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Provision",
    //   key: 'attributes.provAgencyTotal',
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "pauschal Rabatt",
    //   key: 'attributes.provSeller',
    //   options: {
    //     contentFunction: (data: any) => {
    //       const seg = data.item.attributes
    //       return seg ? seg.discountSeller+seg.discountPfs : 'NA'
    //     }
    //   }
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Monatsrabatt",
    //   key: 'attributes.discountCg',
    //   options: {
    //     tooltip: 'Wird proportional zur Aufenthaltsdauer ausgezahlt.'
    //   }
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Reiseart",
    //   key: 'attributes.travelType',
    //   options: {
    //     contentFunction: (data: any) => {
    //       const seg = data.item.attributes
    //       return (seg && seg.travelType && seg.travelCost) ? `${seg.travelType}: ${seg.travelCost}` : 'NA'
    //     }
    //   }
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "2 Pers. HH.",
    //   key: 'attributes.dubHh',
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "2 Senioren HH.",
    //   key: 'attributes.dubCr',
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Nachtpflege",
    //   key: 'attributes.nightCare',
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Führerschein",
    //   key: 'attributes.driversLicense',
    // },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Zahlung erhalten",
      key: 'attributes.clientPaid',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Notiz",
      key: 'attributes.note',
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Rabatt",
    //   key: 'attributes.discountAgency',
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Rabatt PfS",
    //   key: 'attributes.provPfs',
    //   seller: true,
    //   options: {
    //     contentFunction: (data: any) => {
    //       const segment = data.item.attributes
    //       return segment.discountPfs+segment.discountSeller
    //     }
    //   }
    // },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bearbeiten',
            icon: ['fas', 'pencil-alt'],
            action: editSegment,
          }
        ]
      }
    },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "segDiscPfS",
  //   key: 'attributes.discountPfs',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "segDiscVP",
  //   key: 'attributes.discountSeller',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "segDiscAg",
  //   key: 'attributes.discountAgency',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "conDiscPfS",
  //   key: 'attributes.contractDiscountPfs',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "conDiscVP",
  //   key: 'attributes.contractDiscountSeller',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "conDiscAg",
  //   key: 'attributes.contractDiscountAgency',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "Reisetyp",
  //   key: 'attributes.travelType',
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   title: "Reisekosten",
  //   key: 'attributes.travelCost',
  // }
  ]
}