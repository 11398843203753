export const internalContractSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/contract-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        active: { type: "boolean", title: "Aktiv" },
        archived: { type: "boolean", title: "Archiviert" },
        clientSigned: { type: "boolean", title: "Kunden Unterschrift" },
        agencySigned: { type: "boolean", title: "Agentur Unterschrift" },
      }
    }
  }
}

export const contractSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/contract-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        active: { type: "boolean", title: "Aktiv" },
        archived: { type: "boolean", title: "Archiviert" },
        clientSigned: { type: "boolean", title: "Kunden Unterschrift" },
        agencySigned: { type: "boolean", title: "Agentur Unterschrift" },
      }
    }
  }
}

export const internalUiContractSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/contract-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        active: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/active",
          "ui:label": "Aktiv",
          "ui:type": "checkbox",
          "ui:classNames": ["col-3"]
        },
        archived: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/archived",
          "ui:label": "Archiviert",
          "ui:type": "checkbox",
          "ui:classNames": ["col-3"]
        },
        clientSigned: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/clientSigned",
          "ui:label": "Kundenunterschrift",
          "ui:type": "checkbox",
          "ui:classNames": ["col-3"]
        },
        agencySigned: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/agencySigned",
          "ui:label": "Agenturunterschrift",
          "ui:type": "checkbox",
          "ui:classNames": ["col-3"],
          // "ui:options": {disabled: true}
        },
      }
    }
  }
}

export const uiContractSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/contract-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        active: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/active",
          "ui:label": "Aktiv",
          "ui:type": "checkbox",
          "ui:classNames": ["col-6"]
        },
        archived: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/archived",
          "ui:label": "Archiviert",
          "ui:type": "checkbox",
          "ui:classNames": ["col-6"]
        },
        clientSigned: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/clientSigned",
          "ui:label": "Kundenunterschrift",
          "ui:type": "checkbox",
          "ui:classNames": ["col-6"]
        },
        agencySigned: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/agencySigned",
          "ui:label": "Agenturunterschrift",
          "ui:type": "checkbox",
          "ui:classNames": ["col-6"],
          // "ui:options": {disabled: true}
        },
      }
    }
  }
}

export const providerContractSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/contract-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        // active: { type: "boolean", title: "Aktiv" },
        // archived: { type: "boolean", title: "Archiviert" },
        // clientSigned: { type: "boolean", title: "Kundenunterschrift" },
        agencySigned: { type: "boolean", title: "Agenturunterschrift" },
      }
    }
  }
}

export const providerUiContractSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/contract-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        // clientSigned: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/clientSigned",
        //   "ui:label": "Kundenunterschrift",
        //   "ui:type": "checkbox",
        //   "ui:classNames": ["col-6"],
        //   "ui:options": {disabled: true}
        // },
        agencySigned: {
          $ref: "https://crm.pflegehilfe-senioren.de/contract-model.json#/properties/attributes/properties/agencySigned",
          "ui:label": "Unterschrieben",
          "ui:type": "checkbox",
          "ui:classNames": ["col-12"]
        },
      }
    }
  }
}