
  import { defineComponent, ref, computed, watch, reactive, getCurrentInstance } from "vue"
  import { internalCareStayColumns as careStayColumns } from '@/view_scripts/table_columns/care_stay_columns'
  import { CareStay } from "@/models/care_stays";
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { careStaySchema, internalUiCareStaySchema as uiCareStaySchema } from "@/view_scripts/form_schemas/care_stay"
  import { getRefreshData } from "@/utils/refreshData"
  export default defineComponent({
    components: {
      SchemaForm
    },
    props: { items: { type: Array, required: true } },
    setup(props: any, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const editCareStayModal = ref()
      const csActionModal = ref()
      const careStayToEdit = ref<ResourceObject<string, CareStay>>()
      const refreshData = getRefreshData()
      const availableStages = ['Neu', 'Vorgestellt', 'Angenommen', 'Abgelehnt', 'Abgebrochen', 'Bestätigt', 'Alle']
      const stage = ref('Alle')
      const csActionData = reactive<{[key: string]: any}>({
        type: null,
        reasons: [],
        wrongstate: false,
        customReason: '',
        selectedReason: null,
        title: 'Default Titel',
        billEnd: null,
        departure: null,
        action: () => {alert('es wurde keine Aktion festgelegt')}
      })
      const resetCsActionData = () => {
        csActionData.reasons = []
        csActionData.wrongstate = false
        csActionData.customReason = ''
        csActionData.selectedReason = null
        csActionData.title = 'Default Titel'
        csActionData.billEnd = null
        csActionData.departure = null
      }
      const editCareStay = (data: any) => {
        careStayToEdit.value = JSON.parse(JSON.stringify(data.item))
        editCareStayModal.value.show()
      }

      const getReasons = async (sysId: string) => {
        const listQuery = {'filter[sys_id]': sysId, include: 'sublists'}
        showLoading.value = true
        await root.$store.dispatch('lists/load', listQuery)
        const reasons = root.$store.state.lists.data[0].attributes.sublists
          .map((rr)=>{ return {value: rr.id, text: rr.attributes?.name} })
          .concat({value: 'new', text: "Eigenen Grund angeben..."})
        showLoading.value = false
        return reasons
      }

      const filteredItems = computed(() => props.items.filter(cs => {
        return (stage.value === 'Alle' || cs.attributes.stage === stage.value)
      }))

      // const terminateCareStay = (data: any) => {
      //   resetCsActionData()
      //   csActionData.type = 'terminate'
      //   csActionData.title = 'Pflegeeinsatz beenden'
      //   csActionData.billEnd = data.item.attributes.billEnd.split('T')[0]
      //   csActionData.departure = data.item.attributes.departure.split('T')[0]
      //   csActionData.action = (careStay: any) => {
      //     data = {
      //       attributes: data.item.attributes,
      //       usecase: 'cs_terminate'
      //     }
      //     root.$store.dispatch('care_stays/edit', { id: data.item.id, body: data })
      //     root.$nextTick(() => {
      //       refreshData()
      //       csActionModal.value.hide()
      //     })
      //   }
      //   csActionModal.value.show()
      // }

      const abortCareStay = async (data: any) => {
        resetCsActionData()
        csActionData.type = 'abort'
        const reasons = await getReasons('suggestion_cancel_reasons')
        reasons.unshift({value: null, text: 'Grund für den Abbruch wählen'})
        csActionData.title = 'Pflegeeinsatz abbrechen'
        csActionData.reasons = reasons
        csActionData.action = (event: any) => {
          const updateData = {
            attributes: data.item.attributes,
            reason: csActionData.selectedReason,
            customReason: csActionData.customReason,
            usecase: 'cs_cancel',
          }
          root.$store.dispatch('care_stays/edit', { id: data.item.id, body: updateData })
          root.$nextTick(() => {
            refreshData()
            csActionModal.value.hide()
          })
        }
        csActionModal.value.show()
      }

      const selectCareStaySegments = (data: any) => {
        emit('careStaySelected', data.item.id)
      }
      const careStayActionColumns = ref(careStayColumns({editCareStay, abortCareStay, selectCareStaySegments}))
      const saveCareStay = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        showLoading.value = true
        root.$store.dispatch('care_stays/edit', { id: careStayToEdit.value.id, body: careStayToEdit.value })
          .then((r) => {
            editCareStayModal.value.hide()
            refreshData()
          })
          .finally(() => {
            showLoading.value = false
          })
      }
      return {
        showLoading,
        editCareStayModal,
        csActionModal,
        careStaySchema,
        uiCareStaySchema,
        saveCareStay,
        careStayToEdit,
        careStayActionColumns,
        csActionData,
        availableStages,
        stage,
        filteredItems
      }
    }
  })
