
  import { defineComponent, ref, computed, reactive, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { Suggestion } from "@/models/suggestions";
  import { suggestionColumns } from "@/view_scripts/table_columns/suggestion_columns";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { Column } from "@/models/table"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { getRefreshData } from "@/utils/refreshData"

  interface Props {
    suggestionIds: string[],
    columns?: Column[],
    isSwap: Boolean
  }

  export default defineComponent({
    props: {
      suggestionIds: {
        type: Array,
        default: () => []
      },
      columns: {
        type: Array,
      },
      tableTitle: {
        type: String,
        default: 'Vorschläge'
      },
      isSwap: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const totalItems = ref<number>(0)
      const sample = ref()
      const rejectModal = ref()
      const ts = new Date()
      const tableRef = ref<any>(null)
      const showLoading = ref(false)
      const swapAcceptModal = ref()

      const suggestionIdsString = computed(() => {
        return props.suggestionIds.join()
      })
      const rejectReasonSelectorPlaceholder = {value: null, text: 'Absagegrund wählen'}
      const acceptData = reactive({
        id: null,
        endDate: null,
        startDate: null,
      })
      const rejectData = reactive<{[key: string]: any}>({
        rejectReasons: [rejectReasonSelectorPlaceholder],
        customReason: '',
        selectedReason: null,
        wrongstate: false,
      })
      const refreshData = getRefreshData()
      const rejectSuggestionId = ref<string>()

      const getItems = async(ctx: BvTableCtxObject) => {
        if (props.suggestionIds.length === 0) return
        const bkQuery = {
          'filter[id]': suggestionIdsString.value,
          'page[number]': ctx.currentPage,
          // 'page[size]': ctx.perPage,
          'page[size]': Math.ceil(props.suggestionIds.length/10)*10,
          'sort': '-createdAt',
        }
        await root.$store.dispatch('suggestions/load', bkQuery)
        const visorIds = extractRelationshipIds({ data: root.$store.state.suggestions.data }, 'visor')
        return Promise.all([getVisors(visorIds), getSegments()]).then( (indices) => {
          const [visorIndex, segmentIndex] = indices
          root.$store.state.suggestions.data.forEach((sugg) => {
            const visorId = (sugg as any).relationships?.visor?.data?.id
            const segmentId = (sugg as any).relationships?.segment?.data?.id
            if (sugg.attributes) {
              sugg.attributes.visor = visorIndex[visorId]
              sugg.attributes.segment = segmentIndex[segmentId]
            }
          })
          const sugs = root.$store.state.suggestions.data
          // const filteredSugs = root.$store.state.suggestions.data.filter((sug: any) => {
          //   const sugIsSwap = sug.relationships?.contract?.data?.id
          //   return props.isSwap ? sugIsSwap : !sugIsSwap
          // })
          totalItems.value = sugs.length
          return sugs
        })
      }

      const getVisors = async (visorIds: string[]) => {
        const visorQuery = {
          'filter[id]': visorIds.join(),
          'include': 'agency'
        }
        await root.$store.dispatch('visors/load', visorQuery)
        const visorIndex = {}
        const visorData = root.$store.state.visors.data
        visorData.forEach((v) => {
          if (v.id) visorIndex[v.id] = v
        })
        return visorIndex
      }

      const getSegments = async () => {
        const segmentQuery = {
          'filter[suggestion_id]': suggestionIdsString.value,
          'page[number]': 1,
          'page[size]': props.suggestionIds.length,
        }
        const tmp = await root.$store.dispatch('segments/load', segmentQuery)
        let cGIIds = extractRelationshipIds({ data: root.$store.state.segments.data }, 'careGiverInstance')
        if (cGIIds.length == 0) {
         cGIIds = extractRelationshipIds({ data: root.$store.state.segments.data }, 'care_giver_instance')
        }
        const cgiQuery = {
          'filter[id]': cGIIds.join(),
          'page[number]': 1,
          'page[size]': cGIIds.length,
          'include': 'care_giver'
        }
        await root.$store.dispatch('care_giver_instances/load', cgiQuery)
        const cGIIndex = {}
        const segmentIndex = {}
        root.$store.state.care_giver_instances.data.forEach((cgi) => { if (cgi.id) cGIIndex[cgi.id] = cgi })
        const retVal = root.$store.state.segments.data.forEach((seg)=>{
          let cGIId = ((seg.relationships?.careGiverInstance  as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
          if (!cGIId) {
            cGIId = ((seg.relationships?.care_giver_instance  as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
          }
          if (seg.attributes) seg.attributes.careGiverInstance = cGIIndex[cGIId]
          if (seg.id) segmentIndex[seg.id] = seg
        })
        return segmentIndex
      }

      const cgiReject = async () => {
        const usecase = props.isSwap ? 'cgi_swap_reject' : 'cgi_reject'
        const data = {
          usecase,
          list_id: rejectData.selectedReason,
          custom_reason: rejectData.customReason
        }
        if (rejectSuggestionId.value){
          showLoading.value = true
          try {
            await root.$store.dispatch('suggestions/edit', { id: rejectSuggestionId.value, body: data })
          } finally {
            showLoading.value = false
            refreshData()
            tableRef.value.$refs.table.refresh()
          }
        }
      }
      const actions = {
        accept: () => {alert('Vorschläge sind obsolet. Bitte mit den Pflegeeinsätzen arbeiten')},
        reject: () => {alert('Vorschläge sind obsolet. Bitte mit den Pflegeeinsätzen arbeiten')},
      }
      // const actions = {
      //   accept: async (data: any) => {
      //     let msg = ''
      //     if (data.item.attributes.stage != 'Vorgestellt') {
      //       msg = "ACHTUNG! Diese BK is nicht im Status: Vorgestellt!\n"
      //     }
      //     msg += 'Soll die BK angenommen werden?'
      //     if (!(await confirm(root, msg))) return
      //     if (props.isSwap) {
      //       // const date = data.item.attributes.segment.attributes.start.split('T')[0]
      //       // acceptData.id = data.item.id
      //       // acceptData.endDate = date
      //       // acceptData.startDate = date
      //       // swapAcceptModal.value.show()
      //       sendSwapAcceptRequest(data.item.id, 'cgi_swap_accept')
      //     } else {
      //       sendSwapAcceptRequest(data.item.id, 'cgi_accept')
      //     }
      //   },
      //   reject: async (data: any) => {
      //     if (data.item.attributes.stage != 'Vorgestellt') {
      //       rejectData.wrongstate = true
      //       // prompt(root, 'Nur Vorgestellte BKs können abgelehnt werden.')
      //     }
      //     rejectSuggestionId.value = data.item.id
      //     const listQuery = {'filter[sys_id]': 'suggestion_reject_reasons',include: 'sublists'}
      //     showLoading.value = true
      //     await root.$store.dispatch('lists/load', listQuery)
      //     rejectData.rejectReasons = root.$store.state.lists.data[0].attributes.sublists
      //       .map((rr)=>{
      //         return {value: rr.id, text: rr.attributes?.name}
      //       })
      //       .concat({value: 'new', text: "Eigenen Grund angeben..."})
      //     rejectData.rejectReasons.unshift(rejectReasonSelectorPlaceholder)
      //     showLoading.value = false
      //     rejectModal.value.show()
      //   }
      // }

      const sendSwapAcceptRequest = (id: string, usecase: string) => {
        showLoading.value = true
        root.$store.dispatch('suggestions/edit', { id, body: { usecase, ...acceptData } })
        .then( (r) => {
          return refreshData()
        }).then( (r) => {
          tableRef.value.$refs.table.refresh()
          showLoading.value = false
        })
      }

      const tableColumns = props.columns || suggestionColumns(actions)

      return {
        getItems,
        totalItems,
        tableColumns,
        suggestionIdsString,
        sample,
        rejectModal,
        rejectData,
        cgiReject,
        tableRef,
        showLoading,
        swapAcceptModal,
        acceptData,
        sendSwapAcceptRequest
      }
    }
  })
