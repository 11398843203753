import {nextMonthStart} from "@/utils/helper"
import { ResourceObject, AttributesObject } from '@/models/jsonapi'

export interface Segment {
  createdAt?: string,
  updatedAt?: string,
  start: string,
  agStart?: string,
  end?: string,
  agEnd?: string,
  price: number,
  provPfs: number,
  provSeller: number,
  discountPfs: number,
  discountSeller: number,
  discountCg: number,
  dubHh: number,
  dubCr: number,
  manualDuration?: number,
  agencyBillPaid?: boolean,
  sellerBillPaid?: boolean,
  suggestionId?: string,
  contractId?: string,
  careGiverInstanceId?: string,
  agencyBillId?: string,
  sellerBillId?: string,
  [key: string]: any
}

export const newSegmentObject = (contractId: string):ResourceObject<string, Segment> => {
  return {
    id: 'new',
    type: 'segments',
    attributes: {
      start: new Date().toISOString(),
      price: 0,
      provPfs: 99,
      provSeller: 200,
      discountPfs: 0,
      discountSeller: 0,
      discountCg: 0,
      dubHh: 0,
      dubCr: 0,
      contractId: contractId,
    }
  }
}

export const extendSegment = (oldSeg: ResourceObject<string, Segment>):ResourceObject<string, Segment> => {
  const newStart = oldSeg.attributes?.start ? nextMonthStart(oldSeg.attributes.start) : new Date().toISOString()
  const newAttrs = {
    ...oldSeg.attributes,
    start: newStart,
    agStart: newStart,
    end: undefined,
    agEnd: undefined,
    discountPfs: 0,
    discountSeller: 0,
    manualDuration: undefined,
    clientPaid: true,
    agencyBillPaid: false,
    sellerBillPaid: false,
    note:''
  } as AttributesObject<Segment>

  return {
    id: 'new',
    type: 'segments',
    attributes: newAttrs
  }
}
